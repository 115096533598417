import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'

import { connect } from 'react-redux'
import Layout from '../../layouts/default'
import Banner from '../../components/sections/_banner/default'
import Heading from '../../components/app-components/dashed-heading'
import Icon from '../../components/app-components/icon'
import SignUpForm from '../../components/app-components/signup-form'
import BannerYellowRoundImg from '../../assets/images/booke-yellow-vertical-lines.png'
import './styles.scss'
import { doSignup } from '../../store/authentication/actions'

const SignUpTemplate = ({
  data: {
    wpPage: { seo },
  },
  signup,
  location,
}) => {
  return (
    <Layout seo={seo}>
      <Banner
        title="Création de compte"
        img={BannerYellowRoundImg}
        bannerClass="signup-banner"
      />

      <Container className="signup-page">
        <Row className="justify-content-center">
          <Col xs="12" md="8" lg="8" className="top-section text-center">
            <Heading className="heading" title="Je crée un compte" />
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <Icon name="icon-photoshoot" paths={5} />
            <p className="pt-3 text-primary">
              <strong>Créer votre compte</strong> ici !
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <SignUpForm
              handleSubmit={signup}
              path={
                location?.state?.signupData ? '/reservation/facturation' : null
              }
              data={location?.state?.signupData || null}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

SignUpTemplate.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
  signup: propTypes.func.isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
}

export const query = graphql`
  query SignUpPage($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      template  {
        templateName
      }
      title
      id
      ...yoastMeta
    }
  }
`

const mapDispatchToProps = dispatch => ({
  signup: formData => dispatch(doSignup(formData)),
})
export default connect(null, mapDispatchToProps)(SignUpTemplate)
