import React, { useState } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import propTypes from 'prop-types'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import ErrorMessage from '../form-error-message'
import Link from '../link'
import Checkbox from '../checkbox'
import { trackGTM } from '../../../helpers/index'

const SignUpForm = ({ handleSubmit, path, data, isCheckout }) => {
  const [form, setForm] = useState({
    firstName: data?.['signup-firstname'] || '',
    lastName: data?.['signup-lastname'] || '',
    email: data?.['signup-email'] || '',
    password: data?.['signup-password'] || '',
    confirmPassword: '',
    acceptConditions: false,
  })
  const [submitted, setSubmit] = useState(false)
  const {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    acceptConditions,
  } = form

  const formSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup
      .string()
      .required()
      .email()
      .max(100),
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).+$/)
      .required(),
    confirmPassword: yup
      .mixed()
      .oneOf([yup.ref('password'), null])
      .required(),
  })

  const {
    register,
    errors,
    formState,
    setError,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setForm({ ...form, [name]: value })
  }

  const submit = async e => {
    e.preventDefault()
    setSubmit(true)
    let res
    if (formState.isValid && acceptConditions) {
      if (path === null) res = await handleSubmit(form)
      else res = await handleSubmit(form, path)
      if (res !== true) {
        setError([
          { name: 'firstName' },
          { name: 'lastName' },
          { name: 'email' },
          { name: 'password' },
          { name: 'confirmPassword' },
          { name: 'acceptConditions' },
        ])
      } else {
        trackGTM({
          event: 'createAccountForm',
          tunnel: isCheckout,
        })
      }
    }
  }
  const formComplete = !(
    !lastName ||
    errors.lastName ||
    !firstName ||
    errors.firstName ||
    !email ||
    errors.email ||
    !password ||
    errors.password ||
    !confirmPassword
  )
  return (
    <Container fluid className="signup-form">
      <Row className="justify-content-center">
        <Col xs={12}>
          <Form onSubmit={e => wrapSubmit(submit(e))}>
            <h6 className="signup-form-title text-right">
              Champs obligatoires*
            </h6>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group lastname-wrapper"
                controlId="lastName"
              >
                <Form.Control
                  placeholder="Nom*"
                  name="lastName"
                  ref={register}
                  defaultValue={lastName}
                  onChange={handleChange}
                  isInvalid={typeof errors.lastName !== 'undefined'}
                />
                <Form.Label>Nom*</Form.Label>
                <ErrorMessage name="lastName" errors={errors} />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group firstname-wrapper"
                controlId="firstName"
              >
                <Form.Control
                  placeholder="Prénom*"
                  name="firstName"
                  ref={register}
                  defaultValue={firstName}
                  onChange={handleChange}
                  isInvalid={typeof errors.firstName !== 'undefined'}
                />
                <Form.Label>Prénom*</Form.Label>
                <ErrorMessage name="firstName" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group"
                controlId="email"
              >
                <Form.Control
                  placeholder="Mail*"
                  name="email"
                  ref={register}
                  defaultValue={email}
                  onChange={handleChange}
                  isInvalid={typeof errors.email !== 'undefined'}
                />
                <Form.Label>Mail*</Form.Label>
                <ErrorMessage name="email" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group password-wrapper"
                controlId="password"
              >
                <Form.Control
                  type="password"
                  placeholder="Mot de passe*"
                  name="password"
                  ref={register}
                  defaultValue={password}
                  onChange={handleChange}
                  isInvalid={typeof errors.password !== 'undefined'}
                />
                <Form.Label>Mot de passe*</Form.Label>
                <ErrorMessage name="password" errors={errors} />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group password-confirm-wrapper"
                controlId="confirmPassword"
              >
                <Form.Control
                  type="password"
                  placeholder="Confirmation du mot de passe*"
                  name="confirmPassword"
                  ref={register}
                  defaultValue={confirmPassword}
                  onChange={handleChange}
                  isInvalid={
                    typeof errors.confirmPassword !== 'undefined' && submitted
                  }
                />
                <Form.Label>Confirmation du mot de passe*</Form.Label>
                <ErrorMessage
                  className="password-confirm-error"
                  name="confirmPassword"
                  show={submitted}
                  errors={errors}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="accept-conditions-wrapper">
              <Form.Group as={Col} xs={12} lg>
                <Checkbox
                  controlId="acceptConditions"
                  isChecked={acceptConditions}
                  className={`check-wrapper ${
                    !acceptConditions && submitted ? 'cgu-check-error' : ''
                  }`}
                  onChange={() =>
                    setForm({ ...form, acceptConditions: !acceptConditions })
                  }
                  ref={register}
                  name="acceptConditions"
                  square
                  style={{ borderColor: !acceptConditions ? 'red' : '' }}
                />
                <Form.Label
                  className={
                    !acceptConditions && submitted ? 'cgu-text-error' : ''
                  }
                >
                  Vous avez pris connaissance et accepté les{' '}
                  <p>
                    <a
                      className={
                        !acceptConditions && submitted ? 'cgu-text-error' : ''
                      }
                      href="/politique-de-confidentialite/"
                      target="_blank"
                    >
                      conditions générales d’utilisation
                    </a>
                  </p>
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <div className="text-center">
              <Button
                size="lg"
                disabled={!formState.dirty || !formComplete}
                variant={
                  formState.dirty && formComplete ? 'warning' : 'disabled'
                }
                type="submit"
              >
                créer mon compte
              </Button>
              <p className="signup-form-login">
                J’ai déjà un compte !{' '}
                <Link to="/connexion/">Je me connecte</Link>
              </p>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

SignUpForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  data: propTypes.objectOf(propTypes.string),
  path: propTypes.objectOf(propTypes.any),
  isCheckout: propTypes.bool,
}
SignUpForm.defaultProps = {
  isCheckout: false,
  data: null,
  path: null,
}

export default SignUpForm
